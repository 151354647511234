@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,1000,2000,3000,4000,5000,6000,7000,8000,9001,1001,2001,3001,4001,5001,6001,7001,8001,900&display=swap')
@import '~tippy.js/dist/tippy.css'
@import '~tippy.js/themes/light.css'
@import '~tippy.js/animations/scale.css'
@import '~firebaseui/dist/firebaseui.css'

body
  margin: 0px
  padding:0px
  color: white
  overflow-y: hidden
  // font-size: 12pt !important
  font-family: "Poppins" !important


#highlight_new_update
  font-weight: 300
  color: black

#highlighter_new_update
    font-weight: 400
    color: black
    padding-left:10px
    padding-right:10px
    border-radius: 5px


::-webkit-scrollbar 
  width: 5px
  height: 5px


/* Track */
::-webkit-scrollbar-track 
  background: transparent


/* Handle */
::-webkit-scrollbar-thumb 
  background: #11111133
  border-radius: 15px
  border: solid #ffffff33 1px

input[type=number]::-webkit-inner-spin-button 
  -webkit-appearance: none



.container 
  display: block
  position: relative
  padding-left: 35px
  /*margin-bottom: 20px*/
  cursor: pointer
  font-size: 25px



/*check box styles*/   

/* Hide the default checkbox */
.container input 
  visibility: hidden
  cursor: pointer


/* Create a custom checkbox */
.mark 
  position: absolute
  top: 0
  left: 0
  height: 20px
  width: 20px
  background-color: transparent
 
li
  display: list-item
  list-style-position: inside
  margin-left:15px

ol
  list-style-type: decimal

ul
  list-style-type: disc

.container:hover input ~ .mark 
  background-color: #555555

.container input ~ .mark 
  background-color: transparent
  border: 1px solid white
  border-radius: 5px 


.container input:checked ~ .mark 
  background-color: white


/* Create the mark/indicator (hidden when not checked) */
.mark:after 
  content: ""
  position: absolute
  display: none


/* Show the mark when checked */
.container input:checked ~ .mark:after 
  display: block


/* Style the mark/indicator */
.container .mark:after 
  left: 6px
  top: 2px
  width: 5px
  height: 10px
  border: solid black
  border-width: 0 3px 3px 0
  border-radius: 2px
  transform: rotate(45deg)


  
/*  ------- Styles for Badges Start ------- */
.badge-container 
  position: relative


.badge 
  position: absolute
  display: flex
  justify-content: center
  align-items: center
  background-color: #165F28
  color: #fff
  font-size: 12px
  text-overflow: ellipsis
  white-space: nowrap
  overflow: hidden
  border-radius: 50%
  box-shadow: 0px 2px 6px -1px rgb(0 0 0 / 50%)

.badge.primary 
  --bg-color: var(--primary)


.badge.secondary 
  --bg-color: var(--secondary)


.badge.top 
  top: -10px

.badge.bottom 
  bottom: -10px

.badge.left 
  left: -10px

.badge.right 
  right: -10px

.badge.small 
  width: 18px
  height: 18px
  font-size: 10px

.badge.medium 
  width: 22px
  height: 22px
  font-size: 11px

.badge.large 
  width: 28px
  height: 28px
  font-size: 12px


.anchor
  color: #08f
  font-weight: 300
  padding-left:20px
  padding-right:20px
  background-color: #151515
  text-decoration: none
  border-radius: 5px

